
.typing-writer{
  display: inline-block;



  border-right: 3px solid rgba(255, 255, 255, 0.75);
  animation: blinkTextCursor 0.75s steps(40) infinite normal;
  text-transform: uppercase;
  color: var(--light-blue);
}


@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, 0.75);
  }
  to {
    border-right-color: transparent;
  }
}


